<template>
    <v-container
        class="d-flex flex-column fill-height align-center w-100"
        fluid
        :style="{background: $vuetify.theme.currentTheme.primary}"
    >
        <div class="mb-auto">
            <v-img src="@/assets/logo-white.svg" max-width="200" contain/>
        </div>

        <v-form @submit.prevent="checkLoginCode">
            <v-card
                :elevation="1"
            >
                <v-card-text class="text-center">
                    <p class="pt-5">Vul hier de inlogcode in uit de Backstage:</p>
                    <v-text-field
                        v-model="upperCaseLoginCode"
                        maxlength="10"
                        counter="10"
                        outlined
                        dense
                        :disabled="isChecking"
                        :error="!isValid"
                        ></v-text-field>
                    <v-btn
                        @click="checkLoginCode"
                        color="primary"
                        :disabled="!isValidFormat || isChecking"
                        rounded
                        large
                    >Inloggen</v-btn>
                </v-card-text>

            </v-card>
        </v-form>

        <div class="mt-auto white--text">
            <v-btn :to="{name:'login'}">Terug</v-btn>
        </div>
    </v-container>
</template>

<script>

export default {
    name: "LoginCode",
    data: () => ({
        code: '',
        isChecking: false,
        isValid: true
    }),
    computed: {
        upperCaseLoginCode: {
            get () {
                return this.code.toUpperCase();
            },
            set(code) {
                this.code = code.toUpperCase();
                this.isValid = true
            }
        },
        isValidFormat () {
            return this.code.length === 10
        }
    },
    methods: {
        checkLoginCode() {
            if (!this.isValidFormat || this.isChecking) {
                return false;
            }

            this.isChecking = true;
            this.isValid = true;
            this.$store.dispatch('handleQrLoginAuthCode', this.code)
                .then(() => {
                    this.$router.push({name: 'home'})
                })
            .catch(e => {
                console.error(e);
                this.isValid = false
                this.isChecking = false;

                if (e.response && e.response.status && e.response.status === 400) {
                    this.$store.dispatch('showSnackbar', {text: 'Ongeldige barcode', color: 'error' });
                    return
                }

                let information = e.message || 'Fout bij controleren';
                this.$store.dispatch('showSnackbar', {text: information, color: 'error' });
            })


        }
    }
}
</script>

<style scoped>

</style>
