<template>
    <v-container
        class="d-flex flex-row fill-height align-center pa-0"
        fluid
    >
        <div class="red white--text flex-grow-1 text-overline text-center" v-if="isOffline">OFFLINE</div>
        <ScanInput
            ref="scanInput"
            default-text="Scan een inlogcode"
            :auto-enable-qr-code-reader="true"
            @input="onScan"
        >
        </ScanInput>
    </v-container>
</template>

<script>

import ScanInput from "@/components/ScanInput";
export default {
    name: "LoginQr",
    components: {ScanInput},
    data: () => ({
        appVersion: process.env.VUE_APP_VERSION,
    }),
    methods: {
        onScan(barcode) {
            this.$store.dispatch('handleQrLoginAuthCode', barcode)
                .then(() => {
                    this.$refs.scanInput.showSuccessStatus('');
                    window.setTimeout(() => {
                        this.$router.push({name: 'home'})
                    }, 200)
                })
            .catch(e => {
                console.error(e);
                if (e.response && e.response.status && e.response.status === 400) {
                    this.$refs.scanInput.showErrorStatus('Ongeldige barcode');
                    return
                }

                let information = e.message || null;
                this.$refs.scanInput.showErrorStatus('Fout bij controleren', information);
            })

        }
    }
}
</script>

<style scoped>

</style>
