<template>
    <v-container
        class="d-flex flex-column fill-height align-center w-100 tc-login-url"
        fluid
        align-center
    >
        <div class="mb-auto">
            <v-img src="@/assets/logo-white.svg" max-width="200" contain/>
        </div>

        <SuccessFailAnimation ref="animation"></SuccessFailAnimation>

    </v-container>
</template>

<script>

import SuccessFailAnimation from "../../components/animation/SuccessFailAnimation";
export default {
    name: "LoginUrl",
    components: {SuccessFailAnimation},
    data: () => ({
        isChecking: false,
        isValid: true
    }),
    mounted() {
        this.$store.dispatch('logout', { logoutBackend: false})
        this.checkLoginCode();
    },
    computed: {
        code () {
            return this.$route.params.code;
        }
    },
    watch: {
        $route() {
            this.checkLoginCode();
        }
    },
    methods: {
        checkLoginCode() {
            if (this.isChecking) {
                return false;
            }

            this.isChecking = true;
            this.isValid = true;
            this.$store.dispatch('handleQrLoginAuthCode', this.code)
                .then(() => {
                    this.$router.push({name: 'home'})
                })
            .catch(e => {
                console.error(e);
                this.isValid = false
                this.isChecking = false;

                if (e.response && e.response.status && e.response.status === 400) {
                    this.$store.dispatch('showSnackbar', {text: 'Ongeldige barcode', color: 'error' });
                    return
                }

                let information = e.message || 'Fout bij controleren';
                this.$store.dispatch('showSnackbar', {text: information, color: 'error' });
            })


        }
    }
}
</script>

<style scoped>
    .tc-login-url {
        background-color: #666;
    }
</style>
